import React, { useRef } from 'react'
import roof from "../images/roof002.webp"
import emailjs from "@emailjs/browser"
import { useState } from 'react';

import { ToastContainer, toast } from 'react-toast'
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';


const Form = () => {
  const formRef = useRef();
  const [sending, setsending] = useState(false)

  const sendEmail = (e) => {
    e.preventDefault();
    setsending(true);

    emailjs
      .sendForm(process.env.REACT_APP_service_ID, process.env.REACT_APP_template_ID, formRef.current, {
        publicKey: process.env.REACT_APP_PUBLIC_KEY,
      })
      .then(
        () => {
          console.log('SUCCESS!');
          toast('Email sent successfully', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setTimeout(()=>{
            window.location.href = "/";
          },1500)
        },
        (error) => {
          console.log('FAILED...', error.text);
          toast.error('Failed... Not able to sent', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        },
      );
  };


  return (
    <div className='container4' style={{ background: "black", marginTop: "50px", position: "relative", display: 'flex', justifyContent: 'center' }}>
      <img width={"100%"} height={"90%"} style={{ opacity: 0.6 }} src={roof} alt="roof" />
      <form className='container3' ref={formRef} onSubmit={sendEmail} style={{ position: "absolute", top: "0", display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: 'center' }}>
        <h1 className='tag6' id="form" style={{ color: "white", marginBottom: "0", fontFamily: "sans-serif", textAlign: "center" }}>Get 10 New Quote-Ready Leads<br />
          <span className='tag2' style={{ color: "#fbbaa8" }}>Within 30 Days, or You Don’t Pay</span></h1>
        <div className='form' style={{ background: "white", display: "flex", flexDirection: "column", gap: "20px", padding: "30px", margin: "20px", borderRadius: "8px" }}>
          <label style={{ marginLeft: "-5px", fontFamily: "sans-serif" }}>Full Name*</label>
          <input style={{ padding: "20px", outline: "none", border: "none", backgroundColor: "#F7FAFC", borderRadius: "10px" }} required placeholder='Full Name' type='text' name='name'></input>
          <label style={{ marginLeft: "-5px", fontFamily: "sans-serif" }}>Webiste*</label>
          <input style={{ padding: "20px", outline: "none", border: "none", backgroundColor: "#F7FAFC", borderRadius: "10px" }} required placeholder='Website' type='text' name='website'></input>
          <label style={{ marginLeft: "-5px", fontFamily: "sans-serif" }}>Email*</label>
          <input style={{ padding: "20px", outline: "none", border: "none", backgroundColor: "#F7FAFC", borderRadius: "10px" }} required placeholder='Email' type='email' name='email'></input>
          <label style={{ marginLeft: "-5px", fontFamily: "sans-serif" }}>Phone*</label>
          <input style={{ padding: "20px", outline: "none", border: "none", backgroundColor: "#F7FAFC", borderRadius: "10px" }} required placeholder='Phone' type='phone' name='phone'></input>
          <button className='tag3' style={{ marginTop: "30px", borderRadius: "10px", border: "none", backgroundColor: "#ef1102", color: 'white', fontFamily: "sans-serif", fontWeight: "bold", opacity: `${sending ? "50%" : "100%"}`, cursor: `${sending ? "not-allowed" : "pointer"}` }}>Get More Leads</button>
        </div>
      </form>
      <ToastContainer/>
    </div>
  )
}

export default Form
