import React from 'react'
import Header from './comp/Header'
import MoreLeads from "./comp/MoreLeads"
import Form from './comp/Form'
import Footer from './comp/Footer'


const App = () => {
  return (
    <div style={{margin:"0",scrollBehavior:"smooth",transition:"all"}}>
      <Header/>
      <MoreLeads/>
      <Form/>
      <Footer/>
    </div>
  )
}

export default App
