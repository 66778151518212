import React from 'react'
import logo from "../images/Logo.jpg"

const Footer = () => {
    return (
        <>
            <div className='container5' style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", margin: "30px" }}>
                    <img src="https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/7dbMbJkAdAEqfoTCewVU/media/6480aa83a2b3b17aa209595a.png" alt="home" width={"80px"} />
                    <h4 style={{ fontFamily: "sans-serif", fontWeight: "normal" }}>Industry Specialists</h4>
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", margin: "30px" }}>
                    <img src="https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/7dbMbJkAdAEqfoTCewVU/media/6480a9df047a35f430057653.png" alt="home" width={"80px"} />
                    <h4 style={{ fontFamily: "sans-serif", fontWeight: "normal" }}>Guaranteed Results</h4>
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", margin: "30px" }}>
                    <img src="https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/7dbMbJkAdAEqfoTCewVU/media/6480aa32a2b3b12aec09591c.png" alt="home" width={"80px"} />
                    <h4 style={{ fontFamily: "sans-serif", fontWeight: "normal" }}>Qualified Leads</h4>
                </div>
            </div>
            <div style={{ width: "100%", backgroundColor: "#fbbaa8",display:'flex',flexDirection:"column",justifyContent:"center",alignItems:"center" }}>
                <img src={logo} width={250} alt='header'></img>
                <h4 style={{ fontFamily: "sans-serif", fontWeight: "bold", color: 'white' ,textAlign:"center"}}>AuraEpexDigital - @2024 All Rights Reserved</h4>
            </div>
        </>
    )
}

export default Footer
