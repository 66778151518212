import React from 'react'
import logo from "../images/Logo.jpg"

const Header = () => {
  return (
    <div style={{height:"auto",backgroundColor:"#fbbaa8",display:"flex",justifyContent:'center'}}>
      <img src={logo} width={250} alt='header'></img>
    </div>
  )
}

export default Header
