import React from 'react'
import roofImg from "../images/roof001.jpeg"

const MoreLeads = () => {
    return (
        <>
            <div style={{ backgroundColor: 'black', position: "relative", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img width={"100%"} height={"700"} style={{ opacity: 0.6 }} src={roofImg} alt="rooftop" />
                <div style={{ position: "absolute", top: "0", display: 'flex', flexDirection: "column", alignItems: 'center', height: "100%", justifyItems: 'center', margin: "10px" }}>
                    <h4 className='tag0' style={{ color: 'white', marginTop: "190px", marginBottom: "2px", fontFamily: "initial" }}>Effortless Business Growth</h4>
                    <h1 className='tag1' style={{ color: "white", marginBottom: "0", fontFamily: "sans-serif" }}>We Generate <span style={{ color: "#fbbaa8" }}>Quote-Ready</span> Home</h1>
                    <h1 className='tag1' style={{ color: "white", marginTop: "0", fontFamily: "sans-serif" }}>Improvement <span style={{ color: "#fbbaa8" }}>Leads for You</span></h1>
                    <h4 className='tag2' style={{ color: 'white', marginTop: "10px", marginBottom: "2px", fontFamily: "initial" }}>No Heavy Lifting Required!</h4>
                    <a href="#form" className='tag3' style={{ scrollBehavior: 'smooth', marginTop: "30px", borderRadius: "100px", backgroundColor: "#ef1102", color: 'white', fontFamily: "sans-serif", fontWeight: "bold", cursor: "pointer", textDecoration: "none" }}>Get More Leads</a>
                </div>
            </div>
            <div className='container1' style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <h1 className='tag4' style={{ color: "black", marginTop: "15px", fontFamily: "sans-serif", textAlign: "center" }}>Who Are <span style={{ color: "#fbbaa8" }}>We</span>?</h1>
                <h4 className='tag5' style={{ color: 'black', textAlign: "center", marginTop: "10px", marginBottom: "2px", fontFamily: "sans-serif", fontWeight: "normal" }}>
                    We're a USA-based agency that helps home improvement companies by delivering top-tier, quote-ready leads through strategic advertising on popular social media sites. Our targeted approach not only frees you up to concentrate on your work, but also ensures your growth is driven by genuinely interested clients, making your business growth smooth and efficient.
                </h4>
            </div>
            <div className='container1' style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <h1 className='tag4' style={{ color: "black", fontFamily: "sans-serif", textAlign: "center" }}>Our <span style={{ color: "#fbbaa8" }}>Process</span></h1>
                <div className='container2' style={{ width: "100%", gap: "30px", justifyContent: "center" }}>
                    <div className="box1" style={{ display: "flex" }}>
                        <img src='https://images.leadconnectorhq.com/image/f_webp/q_80/r_900/u_https://assets.cdn.filesafe.space/7dbMbJkAdAEqfoTCewVU/media/6480a90aa2b3b10669095512.png' width={"50px"} height={"50px"} alt='moreLEads'></img>
                        <h4 style={{ margin: "20px", color: 'black', fontSize: "25px", marginTop: "2px", marginBottom: "2px", fontFamily: "sans-serif" }}>
                            Gather Content
                            <br />
                            <span style={{ fontSize: "15px", fontWeight: "lighter", marginTop: "10px", textWrap: "wrap" }}>In the initial step, we collect visuals that highlight your craftsmanship. These form the foundation of our strategy, serving to attract a greater number of potential clients to your business.</span>
                        </h4>
                    </div>
                    <div className='box1' style={{ display: "flex" }}>
                        <img src='https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/7dbMbJkAdAEqfoTCewVU/media/6480a90a047a35ac1f0575a4.png' width={"50px"} height={"50px"} alt='moreLEads'></img>
                        <h4 style={{ margin: "20px", color: 'black', fontSize: "25px", marginTop: "2px", marginBottom: "2px", fontFamily: "sans-serif" }}>
                            Launch Ads
                            <br />
                            <span style={{ fontSize: "15px", fontWeight: "lighter", marginTop: "10px", textWrap: "wrap" }}>Next, we launch ads based on the content we've collected. These ads are strategically designed and placed on popular social media platforms to capture clients attention.</span>
                        </h4>
                    </div>
                    <div className='box1' style={{ display: "flex" }}>
                        <img src='https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/7dbMbJkAdAEqfoTCewVU/media/6480a90a7a1ec26629352df4.png' width={"50px"} height={"50px"} alt='moreLEads'></img>
                        <h4 style={{ margin: "20px", color: 'black', fontSize: "25px", marginTop: "2px", marginBottom: "2px", fontFamily: "sans-serif" }}>
                            Generate Leads
                            <br />
                            <span style={{ fontSize: "15px", fontWeight: "lighter", marginTop: "10px", textWrap: "wrap" }}>Finally, we collect information from individuals who are genuinely interested in your services. Prioritising quote-ready leads and eliminating tire kickers.</span>
                        </h4>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MoreLeads
